import React, { useEffect } from "react"
import Layout from "../../components/layout"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { DefaultGtag } from '../../utils/misc'
import '../../styles/ValueProposition.css';
import { registerUrl } from '../../utils/misc'
import '../../styles/WhyTas.css'
import '../../styles/index.css'
import GreenMessage from '../../components/GreenMessage';
import JoinUsPage from '../../components/JoinUs';
import { TaskAlt } from "@mui/icons-material";


const mainStyles = {
    display: "flex",
    flexDirection: "column"
}

const firstSection = {
    padding: "0 2rem",
    flexDirection: "column"
}

const secondSection = {
    display: "flex",
    padding: "0 2rem",
    overflow: "visible",
}

const fifthSection = {
    minHeight: "unset",
    position: "relative",
}

function Card({ t, i18n, iconName, title, description, ...props }) {
    return <div className="custom-card">
        <div className="image-container">
            <img src={`/static/media/icons/${iconName}-221130.svg`} alt="online menu with search" width="48" height="48" />
        </div>
        <h3>{title}</h3>
        <span className="second-title">{description}</span>
    </div>
}

function WideCard({ t, title, iconName, description }) {
    return <div className='col'>
        <div className="custom-card small">
            <div className="image-container">
                <img src={`/static/media/icons/${iconName}-221130.svg`} alt="online menu with search" width="48" height="48" />
            </div>
            <h3>{title}</h3>
            <span className="">{description}</span>
        </div>
    </div>
}

const DigitalMenuPage = () => {
    const { t } = useTranslation();
    const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=digital-menu')

    useEffect(() => {
        setTimeout(function () {
            // need delay, otherwise not working on firefox...
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }, 100);
        setRUrl(registerUrl())
    }, [])

    return (
        <Layout>
            <main style={mainStyles} id="menu-page">
                <div className="page ValueProposition menus" style={firstSection}>
                    <div className="subcontainer">
                        <div className="half-page half-page-left">
                            <div>
                                <div className="quote-container">
                                    <h1 className="title">{t(`value-proposition.title.0`)}<span style={{ color: "rgb(237, 82, 89)" }}>Simply Easy</span></h1>
                                    <div className="subtitle" style={{ marginTop: "2rem" }}>{t(`value-proposition.subtitle`)}</div>
                                    <div className="second-title">
                                        <div><TaskAlt />{t(`value-proposition.quotes.0`)}</div>
                                        <div><TaskAlt />{t(`value-proposition.quotes.1`)}</div>
                                        <div><TaskAlt />{t(`value-proposition.quotes.2`)}</div>
                                        <div><TaskAlt />{t(`value-proposition.quotes.3`)}</div>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <a href={rUrl} rel="noreferrer" className="tas-button subtitle"
                                        onClick={() => {
                                            window.gtag_report_conversion(rUrl + "&tier=standard&source=landing.valueproposition")
                                            window.gtag('event', 'click_on_get_started');
                                        }}>
                                        <div>{t(`value-proposition.free-sign-up`)}</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="half-page half-page-right only-lg" style={{ marginTop: "unset", display: "flex" }}>
                            <div style={{ width: "100%", alignItems: "center", display: "flex", zIndex: "1", height: "100%", maxHeight: "55vh", maxWidth: "40rem", margin: "auto" }}>
                                <picture style={{ display: "flex", alignItems: "center" }}>
                                    <source srcSet={"/static/media/images/sample-menu-with-qr-code-8.webp"} type="image/webp" width="640" height="446" loading="lazy" />
                                    <source srcSet={"/static/media/images/sample-menu-with-qr-code-8.png"} type="image/png" width="640" height="446" loading="lazy" />
                                    <img src={"/static/media/images/sample-menu-with-qr-code-8.webp"} alt="Screenshot of the menu" className="menu-sc" width="640" height="446" loading="lazy" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page solutions" id="solutions" style={secondSection}>
                    <div className="subcontainer" style={{ position: "relative", minHeight: "unset", paddingTop: "2rem", height: "100%" }}>
                        <div className="half-page half-page-left" style={{ marginTop: "unset", display: "flex", padding: "2rem 0" }}>
                            <picture style={{ display: "flex", alignItems: "center" }}>
                                <source srcSet={"/static/media/images/admin-interface-menu-w-laptop-2.webp"} type="image/webp" width="642" height="368" loading="lazy" />
                                <source srcSet={"/static/media/images/admin-interface-menu-w-laptop-2.png"} type="image/png" width="642" height="368" loading="lazy" />
                                <img alt="Setup process" className="setup-process" width="642" height="368" loading="lazy" />
                            </picture>
                        </div>
                        <div className="half-page half-page-right" style={{ margin: "auto", display: "flex" }}>
                            <div>
                                <div className="quote-container">
                                    <h2 className="title">{t(`solutions.quote`)}</h2>
                                    <div className="second-title" style={{ marginTop: "2rem" }}>{t(`solutions.title`)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page CustomTheme">
                    <div className="subcontainer">
                        <div className="half-page half-page-left" style={{ margin: "auto", display: "flex" }}>
                            <div>
                                <div className="quote-container">
                                    <h2 className="title">{t(`custom-theme.title`)}</h2>
                                    <div className="second-title" style={{ marginTop: "2rem" }}>{t(`custom-theme.second-title`)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="half-page half-page-right" style={{ marginTop: "unset", display: "flex", padding: "1rem 0" }}>
                            <div style={{ display: "grid", height: "100%", margin: "auto", position: "relative", zIndex: "1" }}>
                                <picture style={{ display: "flex", alignItems: "center" }}>
                                    <source srcSet={"/static/media/images/hero-image.webp"} type="image/webp" width="642" height="368" loading="lazy" />
                                    <source srcSet={"/static/media/images/hero-image.png"} type="image/png" width="642" height="368" loading="lazy" />
                                    <img alt="Setup process" className="setup-process" style={{ width: "100%" }} width="642" height="368" loading="lazy" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="page solutions" id="qrcode" style={secondSection}>
                    <div className="subcontainer" style={{ position: "relative", minHeight: "unset", paddingBottom: "2rem", height: "100%" }}>
                        <div className="half-page half-page-left" style={{ marginTop: "unset", display: "flex", padding: "1rem 0" }}>
                            <picture style={{ display: "flex", alignItems: "center" }}>
                                <source srcSet={"/static/media/images/qr-code-interface.webp"} type="image/webp" width="642" height="368" loading="lazy" />
                                <source srcSet={"/static/media/images/qr-code-interface.png"} type="image/png" width="642" height="368" loading="lazy" />
                                <img alt="Setup process" className="setup-process" width="642" height="368" loading="lazy" />
                            </picture>
                        </div>
                        <div className="half-page half-page-right" style={{ margin: "auto", display: "flex" }}>
                            <div>
                                <div className="quote-container">
                                    <h2 className="title">{t(`qr-code.title`)}</h2>
                                    <div className="second-title" style={{ marginTop: "2rem" }}>{t(`qr-code.quote`)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page WhyTaS" id="why-Take-a-Seat">
                    <h2 className="title">{t(`why.title`)}</h2>
                    <div className="content">
                        <div className="col">
                            <Card iconName="coins" title={t(`why.revenue.0`)} description={t(`why.revenue.1`)} />
                        </div>
                        <div className="col">
                            <Card iconName="satisfaction" title={<>{t(`why.satisfaction.0`)} {t(`why.satisfaction.1`)}</>} description={t(`why.satisfaction.2`)} />
                        </div>
                        <div className="col">
                            <Card iconName="engage" title={t(`why.engage.0`)} description={t(`why.engage.1`)} />
                        </div>
                    </div>
                </div>
                <div id="features" className="page features-page" style={fifthSection}>
                    <h2 className="title">{t(`features.title`)}</h2>
                    <div className="content">
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "1rem"
                        }}>
                            <WideCard iconName="search" title={t(`features.online.0`)} description={<>{t(`features.online.1`)}</>} />
                            <WideCard iconName="paint" title={t(`features.custom.0`)} description={<>{t(`features.custom.1`)}</>} />
                            <WideCard iconName="languages" title={t(`features.multi-lang.0`)} description={t(`features.multi-lang.1`)} />
                            <WideCard iconName="qr-code" title={t(`features.qr-code.0`)} description={t(`features.qr-code.1`)} />
                            <WideCard iconName="print" title={t(`features.paper-menu.0`)} description={<>{t(`features.paper-menu.1`)}</>} />
                            <WideCard iconName="comment" title={t(`features.feedback.0`)} description={<>{t(`features.feedback.1`)}</>} />
                            <WideCard iconName="receipt" title={t(`features.orders.0`)} description={t(`features.orders.1`)} />
                            <WideCard iconName="whatsapp" title={t(`features.whatsapp.0`)} description={<>{t(`features.whatsapp.1`)}</>} />
                            <WideCard iconName="analytics" title={t(`features.analytics.0`)} description={<>{t(`features.analytics.1`)}</>} />
                        </div>
                    </div>
                </div>
                <GreenMessage />
                <JoinUsPage />
            </main>
        </Layout>
    )
}

export default DigitalMenuPage

export const Head = () => {
    const { t } = useTranslation();
    return <>
        <DefaultGtag />
        {/* <link rel="canonical" href="https://takeaseat.io/solutions/digital-menu/" /> */}
        <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/solutions/digital-menu/" />
        <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/solutions/digital-menu/" />
        <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/solutions/digital-menu/" />
        <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/solutions/digital-menu/" />
        <title>Digital Menus Solution for Restaurants</title>
        <meta name="description" content="Digital menu creator for restaurants and bars. Create and host a complete menu in just a few clicks. Print a QR code to make it easily accessible to your guests." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Our digital menu solution" />
        <meta property="og:description" content="Digital menu creator for restaurants and bars. Create and host a complete menu in just a few clicks. Print a QR code to make it easily accessible to your guests." />
        <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
        <meta property="og:url" content="https://takeaseat.io/solutions/digital-menu/" />
        <meta property="og:site_name" content="Take a Seat" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Our digital menu solution" />
        <meta name="twitter:description" content="Digital menu creator for restaurants and bars. Create and host a complete menu in just a few clicks. Print a QR code to make it easily accessible to your guests." />
        <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
        <meta name="twitter:site" content="@Takeaseat_io" />
        <meta name="twitter:creator" content="@Takeaseat_io" />
    </>
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","digital-menu", "green", "joinus", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;